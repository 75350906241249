import React, { useRef, useState, useEffect } from "react";
import { HubConnectionBuilder } from "@microsoft/signalr";
const VideoCall: React.FC = () => {
const [connection, setConnection] = useState<any>(null);
  const localVideoRef = useRef<HTMLVideoElement | null>(null);
  const remoteVideoRef = useRef<HTMLVideoElement | null>(null);
  const localStreamRef = useRef<MediaStream | null>(null);
  const pc1 = useRef<RTCPeerConnection>(new RTCPeerConnection({
    iceServers: [
      { urls: "stun:stun.l.google.com:19302" }
    ]
  }));
  
  const rtcConfig: RTCConfiguration = {
    iceServers: [{ urls: "stun:stun.1.google.com:19302" }],
  };

  const videoConstraints: MediaStreamConstraints = {
    audio: true,
    video: { width: 1280, height: 720 },
  };
useEffect(()=>{
    const connect = new HubConnectionBuilder()
    .withUrl(process.env.REACT_APP_API_URL_FOR_CHAT + "userActivityHub")
    .build();
    connect.start()
    .then(()=>console.log("connected to signaling server"))
    .catch(error=>console.log(error));
    async function ready(){
      await attachLocalMedia();
      //await peerConnection(connect);
    }
    ready();  
    connect.on("ReceiveIceCandidate", async (candidate:string)=>{
      try{
        const myCandidate:RTCIceCandidateInit = JSON.parse(candidate);
        pc1.current.addIceCandidate(myCandidate);
      }
      catch{
        console.error("Error adding ICE candidate:");
      }
    }) 
    setConnection(connect);
},[])
  const attachLocalMedia = async (): Promise<void> => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia(videoConstraints);
      if (localVideoRef.current) {
        localVideoRef.current.srcObject = stream;
        localStreamRef.current = stream;
      }
      else{
        console.log("Stream failed");
      }
      console.log("Local stream obtained and added to video element.");
    } catch (error) {
      console.error("Error accessing local media: ", error);
    }
  };
  const peerConnection = async (): Promise<void> => {
    let remoteDescriptionSet = false;
    try {
      const offer = await pc1.current.createOffer();
      console.log("Offer created by pc1:", offer);
      await pc1.current.setLocalDescription(offer);
      console.log(connection.state)
      if(connection.state=='Connected'){
        connection.invoke("SendOffer", JSON.stringify(offer));
        if (!connection.onReceiveAnswerSet) {
          connection.on("ReceiveAnswer", async (answer:string) => {
            try {
              console.log("Answer received:", answer);
              const myAnswer = JSON.parse(answer);
      
              // Check signaling state before setting the remote description
              if (pc1.current.signalingState === "have-local-offer") {
                await pc1.current.setRemoteDescription(myAnswer);
                remoteDescriptionSet = true;
                console.log("Remote description set successfully.");
              } else {
                console.warn("Cannot set remote description, invalid signaling state:", pc1.current.signalingState);
              }
            } catch (error) {
              console.error("Error processing received answer:", error);
            }
          });
      
          // Mark that the listener has been set up
          connection.onReceiveAnswerSet = true;
        }
        // End of exchange SDP process
        if(remoteDescriptionSet){
          pc1.current.onicecandidate = (event) => {
            try{
            if (event.candidate) {
              //pc2.current.addIceCandidate(event.candidate);
              console.log(event.candidate);
              connection.invoke('SendIceCandidate', JSON.stringify(event.candidate))
            }
          } catch (error) {
            console.error("Error adding ICE candidate:", error);
          }
          };
        }
      }
       // Add tracks from the local stream to pc1
       localStreamRef.current?.getTracks().forEach((track) => {
        pc1.current.addTrack(track, localStreamRef.current!);
        console.log("Added track to pc1:", track);
      });
    } catch (error) {
      console.error("Error during peer connection setup: ", error);
    }
  };
  
  return (
    <div>
      <button onClick={peerConnection}>Live stream</button>
      <video ref={localVideoRef} playsInline autoPlay muted
      style={{width:'100%', height:'70vh'}}
      ></video>
    </div>
  );
};
export default VideoCall;
