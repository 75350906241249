import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { PlusOutlined } from '@ant-design/icons';
import {
    Button,
    Form,
    Input,
    InputNumber,
    Select, message,
    Upload, UploadFile,
    Table
  } from 'antd';
import { values } from 'lodash';
import styles from './Document.module.css';
import { title } from 'process';
const {TextArea} = Input;
const Document = () => {
  const token = localStorage.getItem('token');
    const [options, setOptions] = useState<{value:number, label:string}[]>([]);
    const [documents, setDocuments] = useState<any>({});
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [fileList1, setFileList1] = useState<UploadFile[]>([]);
    const columns = [
        {
            title:'Stt',
            dataIndex:'Stt',
            key:'Stt'
        },
        {
            title:'Tên tài liệu',
            dataIndex:'documentName',
            key:'documentName'
        },
        {
          title:'Đơn giá',
          dataIndex:'price',
          key:'price'
      },
        {
            title:'Tải tài liệu',
            dataIndex:'Download',
            key:'Download',
            render:(text:string, record:any, index:number) => {
              return(
                <a href='#'>Download</a>
              )
            }
        }
    ]
    const normFile = (e: any) => {
        if (Array.isArray(e)) {
          return e;
        }
        return e?.fileList;
      };
      const handleChange = ({ file }:any) => {
        setFileList(file ? [file] : []);
      };
      const handleChange1 = ({ file }:any) => {
        setFileList1(file ? [file] : []);
      };
    const hangleSubmit = async (values:any) => {
        const formData = new FormData();
    
    if (fileList.length > 0 && fileList[0].originFileObj) {
      formData.append('MyFile', fileList[0].originFileObj as Blob);
    } else {
      message.warning('Please upload a file');
      return;
    }
    if (fileList1.length > 0 && fileList1[0].originFileObj) {
        formData.append('ContentImg', fileList1[0].originFileObj as Blob);
      }
    // Append other form data if needed
    Object.keys(values).forEach((key) => {
      if (key !== 'attachments') {
        formData.append(key, values[key]);
      }
    });
    formData.append('LecturerCode', localStorage.getItem('userName')??'GV0001');
    try {
      const response = await axios.post(process.env.REACT_APP_API_URL + 'api/DocumentCategory/documents', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`
        },
      });
      if(response.status == 200){
        message.success('Upload successful');
        let data = await getDocuments(1, 5);
        setDocuments(data);
      }
      else{
        message.warning("Upload document failed")
      }
    } catch (error) {
      message.error('Upload failed');
      console.error('Error:', error);
    }
}
  const getDocuments = async (pageNumber:number, pageSize:number): Promise<any> => {
    console.log("áhlfkkalf" + localStorage.getItem('emailData'));
    return new Promise(resolve=>{
      fetch(process.env.REACT_APP_API_URL + 'api/DocumentCategory/lecturer/' + localStorage.getItem('emailData')
      + '/' + pageNumber + '/' + pageSize, {
        method:'GET',
        headers:{
          'Accept':'application/json',
          'Content-Type':'application/json',
          'Authorization': `Bearer ${token}`
        }
      }).then(response=>response.json())
      .then(data=>resolve({summary: data.summary, details:data.details.map((item:any, index:number)=>{
        return {Stt: index+1, documentName:item.documentName,
          price: item.price, FilePath: item.filePath}
      })}))
      .catch(error=>console.log(error))
    })
  }
    useEffect(()=>{
      async function getData(){
          let data = await getDocuments(1, 5);
          setDocuments(data);
      }
      getData();
            fetch(process.env.REACT_APP_API_URL + 'api/DocumentCategory/category', {
                method:'GET',
                headers: {
                    'Accept':'application/json',
                    'Content-Type':'application/json'
                }
            }).then(response=>response.json())
            .then(data=>{
                const options = data.map((item: any) => {
                    return { value: item.id, label: item.name };
                });
            
                // Insert {value: -1, label: 'All'} at the beginning
                setOptions(options);
            })
        },[]);
    return(
        <div className={styles.container}>
        <div className={styles.leftside}>
            <Form
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        style={{ maxWidth: 1000 }} onFinish={hangleSubmit}
      >
        <Form.Item label="Tên tài liệu:" name="DocumentName"
        rules={[{ required: true, message: 'This field must be not empty!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Loại tài liệu" name="CategoryId"
        rules={[{ required: true, message: 'Please select one item!' }]}
        >
          <Select>
            {options.map(item=>{
                return(
                <Select.Option value={item.value}>{item.label}</Select.Option>
            )
            })}
          </Select>
        </Form.Item>
        <Form.Item label="Giá:" name="Price"
        rules={[{ required: true, message: 'This field must be not empty!' }]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item label="Mô tả ngắn" name="Abstraction"
        rules={[{ required: true, message: 'This field must be not empty!' }]}
        >
          <TextArea rows={4} />
        </Form.Item>
        <Form.Item label="Đính kèm tài liệu" valuePropName="fileList" getValueFromEvent={normFile}
        rules={[{ required: true, message: 'This field must be not empty!' }]}
        >
          <Upload listType="picture-card"
          fileList={fileList}
          onChange={handleChange}
          maxCount={1}
          >
            {fileList.length === 0 && (
            <button style={{ border: 0, background: 'none' }} type="button">
              <PlusOutlined />
              <div style={{ marginTop: 8 }}>Upload</div>
            </button>
          )}
          </Upload>
        </Form.Item>
        <Form.Item label="Hình ảnh" valuePropName="fileList1" getValueFromEvent={normFile}
        >
          <Upload listType="picture-card"
          fileList={fileList1}
          onChange={handleChange1}
          maxCount={1}
          >
            {fileList1.length === 0 && (
            <button style={{ border: 0, background: 'none' }} type="button">
              <PlusOutlined />
              <div style={{ marginTop: 8 }}>Upload</div>
            </button>
          )}
          </Upload>
        </Form.Item>
        <Form.Item label=" ">
          <Button htmlType='submit'>Lưu dữ liệu</Button>
        </Form.Item>
      </Form>
      </div>
      <div className={styles.rightside}>
          <Table columns={columns} dataSource={documents.details} 
          pagination={{
            current: pageNumber, // Set the current page
            pageSize: pageSize, // Number of items per page
            showSizeChanger: true, // Allow users to change page size
            pageSizeOptions: ["5", "10", "20"], // Options for page size
            showQuickJumper: true, // Show input for quick navigation
            total: documents.summary, // Total number of data items
            onChange:async(page, pageSize) => {
                let document = await getDocuments(page, pageSize);
                setDocuments(document);
                setPageNumber(page); setPageSize(pageSize);
            }
          }}
          />
      </div>
        </div>
    )
}
export default Document;