import React, {useState} from 'react';
import { Avatar } from 'antd';
import { UserOutlined, LogoutOutlined } from '@ant-design/icons';
import './header.css';

interface HeaderProps {
    handleSelectItem:(itemIndex:number) => void;
  }
  
const StudentLayoutHeader = (props:HeaderProps) => {
const [selectedItem, setSelectedItem] = useState(1);
const [menuOpen, setMenuOpen] = useState(false);
const toggleMenu = () => {
  setMenuOpen(!menuOpen);
};
  return (
    <div className='navContainer'>
    <div className="navbar">
      <div className="logo-section">
        <img src="images/logo.png" alt="Logo 5" />
      </div>
      <div className="menu-icon">
            <img src="images/menu.png" style={{width:'52px', height:'52px'}} onClick={toggleMenu}
            alt="Menu Icon"/>
      </div>
    </div>
    <div className={menuOpen==true?'link-section.show':'link-section'}>
      <div className='menuItem'>
      <img src={selectedItem==1?"images/viewJdActive.png":"images/viewJd.png"} />&nbsp;&nbsp;
      <a style={selectedItem==1?{color:'#ff5a00'}:undefined} onClick={()=>{props.handleSelectItem(1)
          setSelectedItem(1);  
      }}
        >{localStorage.getItem('userFullName')!=null?"Thi trắc nghiệm":"Ôn tập kiến thức"}</a>
      </div>
      {localStorage.getItem('userFullName')!=null?
      <div className='menuItem'>
      <img src={selectedItem==3?"images/viewJdActive.png":"images/viewJd.png"} />&nbsp;&nbsp;
      <a style={selectedItem==3?{color:'#ff5a00'}:undefined} onClick={()=>{props.handleSelectItem(3)
          setSelectedItem(3);  
      }}
        >{"Kho tài liệu"}</a>
      </div>:null}
        {localStorage.getItem('userFullName')!=null?<div className='menuItem'>
        <img src={selectedItem==2?"images/onlineRegisterActive.png":"images/onlineRegister.png"} />&nbsp;&nbsp;
        <a style={selectedItem==2?{color:'#ff5a00'}:undefined}
        onClick={()=>{props.handleSelectItem(2)
          setSelectedItem(2);
        }}>{localStorage.getItem('roleData')=='4'?'Hồ sơ cá nhân':'Báo cáo tiến độ'}</a>&nbsp;&nbsp;</div>:null}
        {localStorage.getItem('userFullName')!=null?<div className='menuItem'>
        <LogoutOutlined />&nbsp;&nbsp;<a onClick={()=>{
          localStorage.clear();
          window.location.reload();
        }}>Đăng xuất</a></div>:null}
        <div className="user-profile">
          <Avatar size="large" icon={<UserOutlined />} src="/path/to/user/avatar.png" />
          <span style={{color:'#ffffff', fontWeight:'600'}}>&nbsp;&nbsp;{localStorage.getItem('userFullName')??"Anonymous user"}</span>
        </div>
      </div>
    </div>
  );
};

export default StudentLayoutHeader;
