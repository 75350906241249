import React, {useState, useEffect} from "react";
import { Select, Input } from "antd";
import BookList from "../book/BookList";
import type { GetProps } from 'antd';
import './style.css';

type SearchProps = GetProps<typeof Input.Search>;

const { Search } = Input;
const BooksContainer = () => {
    const [options, setOptions] = useState<{value:number, label:string}[]>([]);
    const [selectedItems, setSelectedItems] = useState<number>(-1);
    const [searchText, setSearchText] = useState('___');
    const onSearch: SearchProps['onSearch'] = (value, _e, info) => {
        setSearchText(value==''?'___':value);
    }
    useEffect(()=>{
        fetch(process.env.REACT_APP_API_URL + 'api/DocumentCategory/category', {
            method:'GET',
            headers: {
                'Accept':'application/json',
                'Content-Type':'application/json'
            }
        }).then(response=>response.json())
        .then(data=>{
            const options = data.map((item: any) => {
                return { value: item.id, label: item.name };
            });
        
            // Insert {value: -1, label: 'All'} at the beginning
            options.unshift({ value: -1, label: 'Tất cả' });
        
            setOptions(options);
        })
    },[]);
    return(
        <div className="booklist-container">
            <div className="booklist-search">
            <Select style={{width:'50%'}}
      placeholder="Inserted are removed"
      value={selectedItems}
      onChange={(value:number)=>setSelectedItems(value)}
      options={options}
    />
            <Search placeholder="input search text" allowClear onSearch={onSearch} 
            style={{ width: '50%' }} />
            </div>           
    <BookList catId={selectedItems} searchText={searchText} />
        </div>
    )
}
export default BooksContainer;