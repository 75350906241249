import React, {useEffect} from "react";
import { useSearchParams } from 'react-router-dom';
const PaymentResult = (props:{id:string|null}) => {
    const [queryParams] = useSearchParams();
    const transactionId = queryParams.get('vnp_TxnRef');
    useEffect(() => {
        fetch(process.env.REACT_APP_API_URL+'api/Payment?' + queryParams.toString())
            .then(res => res.json())
            .then(async (data) => {
                if (data.success === true) {
                    let response = await fetch(process.env.REACT_APP_API_URL+'api/DocumentCategory/files/' 
                        + data.orderId + '/' + data.paymentId + '/' + props.id)
                    if(response.status == 200){
                        let blob = await response.blob();
                        const url = window.URL.createObjectURL(blob);
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', props.id??"");
                            document.body.appendChild(link);
                            link.click();
                            link.remove();
                    }
                    else{
                        alert("Bạn chưa thanh toán nên không thể download");
                    }
                    
                } else {
                    alert("Payment failed. Please try again.");
                }
            });
    }, [queryParams]);
    return(
        <>
            <h1>Processing Payment...</h1>
        </>
    )
}
export default PaymentResult;