import React, { useState } from 'react';
import { Layout, Menu, theme } from 'antd';
import VideoCall from '../../components/videocall';
import ReceiveVideoCall from '../../components/receivevideocall';
const { Header, Content, Footer } = Layout;
const LiveStream: React.FC = () => {
    const [selectedIndex, setSelectedIndex] = useState(1);
    const items = [{
        key:1, label: 'Broadcast Livestream', onClick:()=>setSelectedIndex(1)
    },{
        key:2, label: 'Receive Livestream', onClick:()=>setSelectedIndex(2)
    }]
    
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  return (
    <Layout>
      <Header
        style={{
          position: 'sticky',
          top: 0,
          zIndex: 1,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div className="demo-logo" />
        <Menu
          theme="dark"
          mode="horizontal"
          defaultSelectedKeys={['1']}
          items={items}
          style={{ flex: 1, minWidth: 0 }}
        />
      </Header>
      <Content style={{ padding: '0 48px' }}>
        <div
          style={{
            padding: 24,
            minHeight: 380,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          {selectedIndex==1?<VideoCall />:<ReceiveVideoCall />}
        </div>
      </Content>
      <Footer style={{ textAlign: 'center' }}>
        Research Development and Training Team ©{new Date().getFullYear()} Alta Software
      </Footer>
    </Layout>
  );
};

export default LiveStream;