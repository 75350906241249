import React, { useRef, useState, useEffect } from "react";
import { HubConnectionBuilder } from "@microsoft/signalr";
const ReceiveVideoCall: React.FC = () => {
const [connection, setConnection] = useState<any>(null);
  const remoteVideoRef = useRef<HTMLVideoElement | null>(null);
  const pc2 = useRef<RTCPeerConnection>(new RTCPeerConnection({
    iceServers: [
      { urls: "stun:stun.l.google.com:19302" }
    ]
  }));
  const [isDisconnectDisabled, setDisconnectDisabled] = useState(true);
  const videoConstraints: MediaStreamConstraints = {
    audio: true,
    video: { width: 1280, height: 720 },
  };
useEffect(()=>{
    const connect = new HubConnectionBuilder()
    .withUrl(process.env.REACT_APP_API_URL_FOR_CHAT + "userActivityHub")
    .build();
    connect.start()
    .then(()=>console.log("connected to signaling server"))
    .catch(error=>console.log(error)); 
    peerConnection1(connect);
    setConnection(connect);
    
},[])
  const peerConnection1 = async (connect:any): Promise<void> => {
    let remoteDescriptionSet = false;
    try {
      connect.on("ReceiveOffer", async (offer:string) => {
        try {
          // Construct the RTCSessionDescriptionInit object
          const myoffer:RTCSessionDescriptionInit=JSON.parse(offer)
          // Set the remote description using the constructed object
          console.log("Remote description set at PC1:", myoffer);
          await pc2.current.setRemoteDescription(myoffer); 
          console.log("Signaling state before setting remote description:", pc2.current.signalingState);
          const answer = await pc2.current.createAnswer(); 
          await pc2.current.setLocalDescription(answer);
          connect.invoke("SendAnswer", JSON.stringify(answer)); 
          remoteDescriptionSet = true;
        } catch (error) {
          console.error("Error processing received answer:", error);
        }
      });
      console.log(remoteDescriptionSet);
      // End of exchange SDP process
      if(remoteDescriptionSet){
        pc2.current.onicecandidate = (event) => {
          try{
            if (event.candidate) {
              //pc1.current.addIceCandidate(event.candidate);
              console.log(event.candidate);
              connect.invoke('SendIceCandidate', JSON.stringify(event.candidate))
            }
          } catch (error) {
            console.error("Error adding ICE candidate:", error);
          }
        };
      }  
      connect.on("ReceiveIceCandidate", async (candidate:string)=>{
        try{
          const myCandidate:RTCIceCandidateInit = JSON.parse(candidate);
          pc2.current.addIceCandidate(myCandidate);
        }
        catch{
          console.error("Error adding ICE candidate:");
        }
      })   
      pc2.current.ontrack = (event) => {
        console.log("Remote track received:", event.streams[0]);
        if (remoteVideoRef.current) {
          if (remoteVideoRef.current.srcObject !== event.streams[0]) {
            remoteVideoRef.current.srcObject = event.streams[0];
            console.log("Remote video srcObject set:", event.streams[0]);
          } else {
            console.log("Remote video srcObject already set. Skipping update.");
          }
          remoteVideoRef.current
            .play()
            .then(() => console.log("Remote video playback started."))
            .catch((err) => console.error("Error forcing video playback:", err));
        } else {
          console.error("Remote video element is null.");
        }
      };
    } catch (error) {
      console.error("Error during peer connection setup: ", error);
    }
  };
  return (
    <div>
      <video ref={remoteVideoRef} playsInline autoPlay muted
      style={{width:'100%', height:'70vh'}}
      ></video>
    </div>
  );
};
export default ReceiveVideoCall;
