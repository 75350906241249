import { LaptopOutlined, NotificationOutlined, UserOutlined } from '@ant-design/icons';
import { MenuProps, Row, message } from 'antd';
import { Layout, Menu, Form, Col } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import React, {useState, useEffect} from 'react';
//import 'antd/dist/antd.css';
import {ListQuestion} from '../listQuestion';
import Student from '../student';
import TestResults from '../testResults';
import EnableTest from '../config';
import { useAppDispatch } from '../../configs/hook';
import {getTestResult} from '../testResults/testResultSlice';
import {getStudent} from '../student/studentSlice';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import ChangePass from '../changePass';
import Document from '../documents/Document';
const { Header, Content, Sider } = Layout;
type SizeType = Parameters<typeof Form>[0]['size'];
type Subject = {
  monhocid: number,
  tenmonhoc: string
}
type SubjectType = {
  id: number;
  tenmonhoc: string;
  title: string;
  taxCode: string;
}
const Main = () => {
const dispatch = useAppDispatch();
const [itemIndex, setItemIndex] = useState(0);
const items1: MenuProps['items'] = ['Ngân hàng câu hỏi',
 'Lớp học phần','Xem điểm', 'Đổi mật khẩu', 'Quản lý tài liệu', 'Đăng xuất'].map((key, index) => ({
  key,
  label: key,
  onClick: ()=>{
      if(key=='Đăng xuất')
        {
            localStorage.clear();
            window.location.reload();
        }
        setItemIndex(index);
      }
}));
const [emailData, setEmailData] = useState(localStorage.getItem('emailData'));
const [mySubjects, setMySubjects] = useState<Subject[]>();
const myCourses = useSelector((state:RootState)=>state.student);
const [items2, setItem2] = useState<MenuProps['items']>();
useEffect(()=>{
  dispatch(getTestResult(emailData!=null?emailData:''));
  dispatch(getStudent(emailData!=null?emailData:''));
},[]);
const token = localStorage.getItem('token');
const [data, setData] = useState<SubjectType[]>([]);
const getAllUniveristy = () => {
  fetch(process.env.REACT_APP_API_URL+'api/Subject/tracnghiem', {
      method: 'GET',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
      },
}).then(response => {
  if(response.status==401){
    message.info("Phiên đăng nhập đã hết hạn!");
    localStorage.clear();
    window.location.reload();
}
return response.json()
})
.then((data)=>{
  console.log(data);      
  setData(data);            
})
.catch(err => console.log(err));
}

const [monhocid, setMonhocid] = useState(0);
useEffect(()=>{
  if(itemIndex==0){
//     const monhocidList = [...Array.from(new Set(myCourses.map((item)=>item.monhocid)))];
// console.log(monhocidList);
// let myFilters = myCourses.filter(a=>monhocidList.includes(a.monhocid));
// setMySubjects(monhocidList.map(item=>{
// let index = myCourses.findIndex(c=>c.monhocid == item);
// return(
// {monhocid:item, tenmonhoc:myCourses[index].tenmonhoc}
// )
// }))
// if(mySubjects!=null || mySubjects != undefined){
  getAllUniveristy();

setItem2(data.map(
(item, index) => {
  return {
    key: item.id,
    icon: React.createElement(LaptopOutlined),
    label: item.id + '-' + (item.taxCode == 'admin@alta.com.vn' ? item.tenmonhoc : item.title),
    onClick: ()=>{
      localStorage.setItem("monhocid", item.id.toString());
      setMonhocid(item.id);
    }
  };
},
))
//}
  }
},[itemIndex]);
console.log(mySubjects);
return(
  <Layout>
    <Header className="header">
      <Row>
        <Col style={{width:'70%'}}>
      <div className="logo" />
      <Menu theme="dark" mode="horizontal" 
      defaultSelectedKeys={['2']} 
      items={items1}
      />
      </Col>
      <Col style={{width:'30%'}}>
      <div style={{display:'inline', float:'right', position:'relative', marginRight:'0px', color:'white', fontWeight:'bold'}}>
          {localStorage.getItem("userFullName")}
        </div>
      </Col>
      </Row>
    </Header>
    <Layout>
      {itemIndex==0?
      <Sider width={300} className="site-layout-background">
        <Menu
          mode="inline"
          defaultSelectedKeys={['1']}
          defaultOpenKeys={['sub1']}
          style={{ height: '100%', borderRight: 0 }}
          items={items2}
        />
      </Sider>:null}
      <Layout style={{ padding: '0 24px 24px' }}>
        <Content
          className="site-layout-background"
          style={{
            padding: 24,
            margin: 0,
            minHeight: 280,
          }}
        >
          {itemIndex == 1
          ? <Student taxCode="admin@alta.com.vn" />
          : itemIndex==2 ? 
          <TestResults /> :
          itemIndex == 3 ? 
          <ChangePass /> :
          itemIndex == 4 ? <Document />:
          <ListQuestion />
          }  
        </Content>
      </Layout>
    </Layout>
  </Layout>
)
};

export default Main;