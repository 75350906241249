import React, {useState, useEffect} from "react";
import { Card, Row, Col, Typography, Badge } from "antd";
import { HeartOutlined } from "@ant-design/icons";
import { downloadFunction } from "../../helpers/download";
import "./BookList.css";

const { Title, Text } = Typography;

interface Book {
  id: string;
  title: string;
  author: string;
  price: number;
  format: string;
  cover: string;
  filePath:string;
}
const BookList = (props:{catId:number, searchText:string}) => {
  const token = localStorage.getItem('token');
  const [totalPage, setTotalPage] = useState(1);
  const [books, setBooks]=useState<Book[]>([]);
  const getBookList = (pageNumber:number) => {
    fetch(process.env.REACT_APP_API_URL + 'api/DocumentCategory/'+ props.catId+'/'+
      props.searchText +'/'+ pageNumber + '/4',{
      method:'GET',
      headers: {
        'Authorization':`Bearer ${token}`
      }
    }).then(response=>response.json())
    .then(data=>{
      setTotalPage(Math.ceil(data.summary/4));
      setBooks(data.details.map((item:any)=>{
        return {id:item.id, title: item.documentName, cover: item.contentImg, author: "NVCMIS",
          price: item.price, filePath: item.filePath,
          format: "eBook"}
      }))
    })
  }
  useEffect(()=>{
    getBookList(1);
    console.log(props.searchText)
  },[props.catId, props.searchText])
  return (
    <div className="book-container">
    <div>
    {Array.from({ length: Math.ceil(books.length / 4) }, (_, i) => i)
    .map(item => {
      const startIndex = item * 4;  // Start index for each row
      return (
        <Row gutter={[16, 16]} key={item} className="bookRow">
          {Array(4).fill(null).map((_, idx) => {
            const book = books[startIndex + idx];
            if (!book) return null;
            return (
              <Col key={book.id} style={{display:'flex'}}>
                <Card style={{width:'300px'}}
                  cover={<img alt={book.title} src={book.cover} style={{width:'300px', height:'300px'}} />}
                  actions={[
                    <HeartOutlined key="favorite" />,
                  ]}
                >
                  <Title level={5} style={{ padding: '0px' }}>{book.title}</Title>
                  <Text type="secondary">by {book.author}</Text>
                  <div className="book-footer">
                    <Text>{book.format}</Text>
                    <Text strong className="price">
                      {book.price} VND
                    </Text>
                    <a href="#" onClick={()=>{
                      localStorage.setItem('book', book.filePath);
                      fetch(process.env.REACT_APP_API_URL+'api/Payment', {
                        method:'POST',
                        headers: {
                          'Accept':'application/json',
                          'Content-Type':'application/json',
                          'Authorization':`Bearer ${token}`
                        },
                        body:JSON.stringify({orderType: 'Online Payment', amount: book.price, 
                          orderDescription: book.id.toString(), name: localStorage.getItem('userFullName')})
                      }).then(response=>response.json())
                      .then(data=>{
                        window.location.href=data;
                      })
                      .catch(error=>console.log(error));
                    }}>Download</a>
                  </div>
                </Card>
              </Col>
            );
          })}
        </Row>
      );
    })}
    </div>
    <div className="pagination">
            <ul>
            {Array.from({ length: totalPage }, (_, index) => index + 1)
            .map(item=>{
                return(
                    <li style={{display:"inline", color:'blue', marginRight:'10px'}}>
                        <a href="#" style={{color:'blue'}}
                        onClick={()=>{getBookList(item);}}
                        >{item}</a>
                    </li>
                )
            })
            }
            </ul>
    </div>
    </div>
  );
};

export default BookList;
