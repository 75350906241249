import React, { ChangeEvent, useEffect, useState } from 'react';
import { Modal, Tabs, DatePicker, Table, Input, Button, Form, message, Radio, Checkbox, Select, Space } from 'antd';
import dayjs from 'dayjs';
import axios from 'axios';
import type { SelectProps } from 'antd';
import './style.css';
import ReactQuill from 'react-quill';
import type { RadioChangeEvent } from 'antd';
import 'react-quill/dist/quill.snow.css';
import { useSelector } from 'react-redux';
 import { RootState } from '../../redux/store';
const TabPane = Tabs.TabPane;
type EditCvType = {
  readOnlyOption: boolean,
  emailData: string,
  handleCallBack: (item:any)=>void
}
const { TextArea } = Input;
const EditCv = (props: EditCvType) => {
  const [cvFile, setCvFile] = useState<File>();
  const [form] = Form.useForm();
  const [isCvEditable, setIsCVEditable] = useState(false);
  const [formData, setFormData] = useState({
    eventName: '', // Default values
    eventDetail: '',
    eventDate: dayjs(new Date()),
    endDate: dayjs(new Date()),
  });
  const [value, setValue] = useState(1);

  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
  };
  const [isCvModalOpen, setIsCvModalOpen] = useState(false);
  const [imageData, setImageData] = useState<File>();
  const [eventType, setEventType] = useState(1);
  const [valueReady, setValueReady] = useState(0);
  const [tabKey, setTabKey] = useState('6');
  const [isInternAddressEditable, setIsInternAddressEditable] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isCVFileEditable, setIsCVFileEditable] = useState(false);
  const [isInternNameEditable, setIsInternNameEditable] = useState(false);
  const [isDateOfBirthEditable, setIsDateOfBirthEditable] = useState(false);
  const [isTelephoneNumEditable, setIsTelephoneNumEditable] = useState(false);
  const [isInternMailEditable, setIsInternMailEditable] = useState(false);
  const [isMajorEditable, setIsMajorEditable] = useState(false);
  const [isUniversityEditable, setIsUniversityEditable] = useState(false);
  const [isForeignLangEditable, setIsForeignLangEditable] = useState(false);
  const [myProfileInfo, setMyProfileInfo] = useState<any>(null);
  const [optionsJDs, setOptionsJDs] = useState<SelectProps['options']>([]);
  const [isIntroductionEditable, setIsIntroductionEditable] = useState(false);
  const [internHistoryId, setInternHistoryId] = useState(0);
  const [emailData, setEmailData] = useState(localStorage.getItem('emailData'));
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalHistoryOpen, setIsModalHistoryOpen] = useState(false);
    const handleGenerateCv = (value:number) => {
      setIsCvModalOpen(false);
      props.handleCallBack({data: myProfileInfo, option:value});
    }
    function callback(key:any) {
      if(key!='5')
          setTabKey(key);
      else
          //props.handleCallBack(myProfileInfo);
          setIsCvModalOpen(true);
    }
    const handleOk = () => {
      setIsModalHistoryOpen(false);
    };
    const handleCancel = () => {
      setIsModalHistoryOpen(false);
    };
    const showModal = () => {
      setIsModalHistoryOpen(true);
    };
    const handleConfirmOk = async (deleteItem:number) => {
        const token = localStorage.getItem('token');
        const response = await fetch(process.env.REACT_APP_API_URL+'api/InternHistory/'+ deleteItem, {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
      })
      if(response.status == 403){
        message.warning("You are only allowed to edit your profile", 3000);
      }
      else{
        let data = await response.json();
        if(data == 'One row deleted'){
          getData();
        }
      }
      }
    const columns = [
      {
        title: 'Id',
        dataIndex: 'id',
        key: 'id',
        hidden: true
      },
      {
        title: 'Bắt đầu',
        dataIndex: 'eventDate',
        key: 'eventDate',
      },
      {
        title: 'Kết thúc',
        dataIndex: 'endDate',
        key: 'endDate',
      },
      {
        title: 'Công việc hoặc đào tạo',
        dataIndex: 'eventName',
        key: 'eventName',
      },
      {
        title: 'Hiển thị trong CV',
        dataIndex: 'displayInCV',
        key: 'displayInCV',
      },
      {
        title: 'Tùy chọn',
        dataIndex:'action',
        key:'action',
        render: (text:string, record:any, index:number) => {
          return(
            <div>
                <Button disabled={props.readOnlyOption} onClick={()=>{
                    setInternHistoryId(record.id);
                    setIsConfirmModalOpen(true);
                }}>Xóa</Button>
                <Button disabled={props.readOnlyOption}
                 onClick={()=>{
                  let eventDate: string = record.eventDate.substring(3, 7) + '-' + record.eventDate.substring(0, 2) + '-01';
                  let endDate: string = record.endDate.substring(3, 7) + '-' + record.endDate.substring(0, 2) + '-01';
                  setInternHistoryId(record.id);
                  setFormData({
                    eventName:record.eventName,
                    eventDate: dayjs(eventDate),
                    endDate: dayjs(endDate),
                    eventDetail: record.eventDetail
                  })
                  setIsModalHistoryOpen(true);
                }}>Cập nhật</Button>
            </div>
          )
        }
      }
    ].filter(x=>!x.hidden);
    const universities = useSelector((state:RootState)=>state.university);
    const updateCv = async (item: {field:string, value:any})=>{
      const token = localStorage.getItem('token');
      const formData = new FormData();
        switch(item.field){
          case 'Avatar':
              formData.append('Avatar', item.value);
              break;
          case 'CVFile':
              formData.append('CVFile', item.value);
              formData.append('TelephoneNum', myProfileInfo.telephoneNum);
              break;
          case 'InternName':
              formData.append('InternName', item.value);
              break;
          case 'ReadyToWork':
              formData.append('ReadyToWork', item.value);
              break;
          case 'JobFields':
              formData.append('JobFields', item.value);
              break;
          case 'DateOfBirth':
              formData.append('DateOfBirth', item.value);
              break;
          case 'TelephoneNum':
              formData.append('TelephoneNum', item.value);
              break;
          case 'InternMail':
              formData.append('InternMail', item.value);
              break;
          case 'Introduction':
              formData.append('Introduction', item.value);
              break;
          case 'InternAddress':
              formData.append('InternAddress', item.value);
              break;
          case 'Major':
              formData.append('Major', item.value);
              break;
          case 'University':
              formData.append('University', item.value);
              break;
          case 'ForeignLanguage':
              formData.append('ForeignLanguage', item.value);
              break;
          default:
              break;
        }
        const config = {    
          headers: { 'content-type': 'multipart/form-data', 'Authorization': `Bearer ${token}` }
        }
        axios.put(process.env.REACT_APP_API_URL+'api/Register/'+ myProfileInfo.id, formData, config)
          .then(response => {
            if(response.status == 403){
                message.warning("You are not allowed to edit CV of anyone else", 3000);
            }
            else{
              getData();
            }           
          })
          .catch(error => {
              console.log(error);
          });
          setImageData(undefined);  
    }
    const [optionsUniversity, setOptionsUniversity] = useState<SelectProps['options']>(
      universities.map(item=>{
        return {value: item.matruong, label:item.tentruong}
      })
    );
    const getAllSubjects = async () => {
      const token = localStorage.getItem('token');
      const rawResponse = await fetch(process.env.REACT_APP_API_URL+'api/Subject/enterprise/admin@alta.com.vn', {
              method: 'GET',
              headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`,
              },
      }).then(response => {
        if(response.status==401){
          message.info("Phiên đăng nhập đã hết hạn!");
          localStorage.clear();
          window.location.reload();
      }
      return response.json()
      })
      .then((data)=>{
           setOptionsJDs(data.map((item:any)=>{
            return {value: item.abbreation, label: item.tenmonhoc}
          }))
    })
      .catch(err => console.log(err));
    }
    const getData = async () => {
      const token = localStorage.getItem('token');
      fetch(process.env.REACT_APP_API_URL+'api/Register/getintern/'
      + props.emailData, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
}).then(response => response.json())
.then((data)=>{
  console.log(data)
  if(data.readyToWork!=null){
    setValueReady(data.readyToWork?1:2);
  }
  else setValueReady(0);
    let formatedData: {id: number, internName: string, dateOfBirth:string, internMail:string,
       internMailReplace:string, internSpecialized: string, university:string, telephoneNum:string, 
       internImageUrl: string, jobFields: string, readyToWork: boolean, expectedJobs?: string[] | any,
       fileUrl: string, introduction:string, internAddress: string, foreignLanguage: string,
       internHistories: {id:number, eventDate:string, endDate:string, eventName:string, major: string,
        displayInCV:boolean, userCreated: string, eventDetail: string }[]
      } = data;
      formatedData.internHistories.map((item=>{
        item.eventDate = item.eventDate.substring(5, 7) + '/' + item.eventDate.substring(0, 4);
        item.endDate = item.endDate.substring(5,7) + '/' + item.endDate.substring(0, 4);
      }))
    setMyProfileInfo(formatedData);         
})
.catch(err => console.log(err));
}
const onFinish = async (values: any) => {
  if(values.endDate<=values.eventDate){
    message.info("Thời điểm kết thúc phải sau thời điểm bắt đầu", 5);
    return;
  }
  else{
    console.log(values);
    const token = localStorage.getItem('token');
    const formData = new FormData();
    formData.append('EventName', values.eventName);
    formData.append('InternId', myProfileInfo.id);
    formData.append('UserCreated', localStorage.getItem('emailData')??'test@alta.com.vn');
    formData.append('EventDate', values.eventDate);
    formData.append('EndDate', values.endDate);
    formData.append('DisplayInCV', values.displayInCV);
    formData.append('EventDetail', values.eventDetail);
    formData.append('EventType', eventType.toString());
    const config = {     
      headers: { 'content-type': 'multipart/form-data', 'Authorization': `Bearer ${token}` }
    }
    if(internHistoryId == 0){
      axios.post(process.env.REACT_APP_API_URL+'api/InternHistory/',formData, config)
      .then(response => {
        if(response.status==403){
          message.warning("You are only allowed to edit your CV", 3000);
        }
        else{
          if(response.data == 'added successfully')
            getData();
        }
      })
      .catch(error => {
          console.log(error);
      });
    }
    else{
      fetch(process.env.REACT_APP_API_URL+'api/InternHistory/'+ internHistoryId, {
        method:'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({eventName: values.eventName, eventDate: values.eventDate, internId: myProfileInfo.id,
          endDate: values.endDate, eventDetail: values.eventDetail, userCreated: localStorage.getItem('emailData')
        })
      })
      .then((response:any) => {
        if(response.status==401){
          message.info("Phiên đăng nhập đã hết hạn!");
          localStorage.clear();
          window.location.reload();
      }
      if(response.status==403){
        message.warning("You are only allowed to edit your CV", 3000);
      }
      else
        if(response.data == 'One row updated')
          getData();
          setInternHistoryId(0);
      })
      .catch(error => {
          console.log(error);
      });
    }
  }
  setIsModalHistoryOpen(false);      
  };
  const handleChange1 = async (value: string[]) => {
    let myValue:string = "";
    value.map(item=>myValue = myValue + item + '_');
    await updateCv({field:'JobFields', value: myValue});
  };
  const onChangeReady = async (e: RadioChangeEvent) => {
    console.log('radio checked', e.target.value);
    await updateCv({field: "ReadyToWork", value:e.target.value})
    setValueReady(e.target.value);
  };
  useEffect(() => {
    // Set default values when component mounts
    form.setFieldsValue(formData);
  }, [formData, form]);
useEffect(()=>{
  const fetchData = async () => {
    await getData(); // Ensure getData is completed
    await getAllSubjects(); // Then call getAllSubjects
  };
  fetchData();
},[props.emailData])
  return (
    <>
    {myProfileInfo!=null ?
    <div className='CvInfoContainer'>
    <div className='LeftPane'>
        <img src={myProfileInfo.internImageUrl} 
            style={{width:'300px', height:'400px', border:'5px solid green'}}
            onClick={()=>{
                setIsModalOpen(!props.readOnlyOption);
            }}
            />
    </div>
    <div className='RightPane'>
    <Tabs defaultActiveKey={tabKey} onChange={callback}>
    <TabPane tab={'Thông tin chung'} key="6">
        <table style={{width:'100%'}}>
            <tr>
              <td className='ColumnLeft'>Họ và tên</td>
              {!props.readOnlyOption?
              <td>
                <>
                  {!isInternNameEditable ?
                <a onClick={()=>setIsInternNameEditable(true)}
                >{myProfileInfo.internName}</a>
                : <input value={myProfileInfo.internName} 
                onChange = {(e)=> {
                  setMyProfileInfo({...myProfileInfo, internName: e.currentTarget.value})
                }}
                onKeyPress = {async (e) => {
                  if(e.key == 'Enter'){ 
                    await updateCv({field:'InternName', value: e.currentTarget.value});                
                    setIsInternNameEditable(false);
                  }                 
                }}
                />}
                </>          
              </td>:<td>{myProfileInfo.internName}</td>}
            </tr>
            <tr>
              <td>{'Ngày sinh'}</td>
              {!props.readOnlyOption?
              <td>
                <>
              {!isDateOfBirthEditable ?
                <a onClick={(e)=>setIsDateOfBirthEditable(true)}>{myProfileInfo.dateOfBirth}</a>
                : <DatePicker defaultValue={dayjs(myProfileInfo.dateOfBirth)} 
                onChange = {async (date:any, dateString:string|string[])=>{
                  await updateCv({field:'DateOfBirth', value: dateString});
                    setIsDateOfBirthEditable(false);
                  }
              }
                />
              }
              </>
              </td>:<td>{myProfileInfo.dateOfBirth}</td>}
            </tr>
            <tr>
              <td>Địa chỉ liên hệ</td>
              {!props.readOnlyOption?
              <td>
                <>
                  {!isInternAddressEditable ?
                <a onClick={()=>setIsInternAddressEditable(true)}
                >{myProfileInfo.internAddress!=null?myProfileInfo.internAddress:'Chưa có dữ liệu'}</a>
                : <input value={myProfileInfo.internAddress} 
                onChange = {(e)=> {
                  setMyProfileInfo({...myProfileInfo, internAddress: e.currentTarget.value})
                }}
                onKeyPress = {async (e) => {
                  if(e.key == 'Enter'){
                    await updateCv({field:'InternAddress', value: e.currentTarget.value});
                        setIsInternAddressEditable(false);
                  }                 
                }}
                />}
                </>         
              </td>:<td>{myProfileInfo.internAddress!=null?myProfileInfo.internAddress:'Chưa có dữ liệu'}</td>}
            </tr>
            <tr>
              <td>Điện thoại liên hệ</td>
              {!props.readOnlyOption?
              <td>
                <>
                  {!isTelephoneNumEditable ?
                <a onClick={()=>setIsTelephoneNumEditable(true)}
                >{myProfileInfo.telephoneNum}</a>
                : <input value={myProfileInfo.telephoneNum} 
                onChange = {(e)=> {
                  setMyProfileInfo({...myProfileInfo, telephoneNum: e.currentTarget.value})
                }}
                onKeyPress = {async (e) => {
                  if(e.key == 'Enter'){
                    await updateCv({field:'TelephoneNum', value: e.currentTarget.value});
                        setIsTelephoneNumEditable(false);
                  }                 
                }}
                />}
                </>         
              </td>:<td>{myProfileInfo.telephoneNum}</td>}
            </tr>
            <tr>
              <td>Thư điện tử</td>
              {!props.readOnlyOption?
              <td>
              <>
                  {!isInternMailEditable ?
                <a onClick={()=>setIsInternMailEditable(true)}
                >{myProfileInfo.internMailReplace??myProfileInfo.internMail}</a>
                : <input value={myProfileInfo.internMailReplace} 
                onChange = {(e)=> {
                  setMyProfileInfo({...myProfileInfo, internMailReplace: e.currentTarget.value})
                }}
                onKeyPress = {async (e) => {
                  if(e.key == 'Enter'){
                    await updateCv({field:'InternMail', value: e.currentTarget.value});
                        setIsInternMailEditable(false);
                  }                 
                }}
                />}
                </>
              </td>:<td>{myProfileInfo.internMailReplace??myProfileInfo.internMail}</td>}
            </tr>
            <tr>
              <td>Chuyên ngành được đào tạo:</td>
              {!props.readOnlyOption?
              <td>
                <>
                  {!isMajorEditable ?
                <a onClick={()=>setIsMajorEditable(true)}
                >{myProfileInfo.major!=null?myProfileInfo.major:'Chưa có dữ liệu'}</a>
                : <input value={myProfileInfo.major} 
                onChange = {(e)=> {
                  setMyProfileInfo({...myProfileInfo, major: e.currentTarget.value})
                }}
                onKeyPress = {async (e) => {
                  if(e.key == 'Enter'){
                    await updateCv({field:'Major', value: e.currentTarget.value});
                    setIsMajorEditable(false);
                  }                 
                }}
                />}
                </>         
              </td>:<td>{myProfileInfo.major!=null?myProfileInfo.major:'Chưa có dữ liệu'}</td>}
            </tr>
            <tr>
              <td>Trường/cơ sở đào tạo:</td>
              {!props.readOnlyOption?
              <td>
                <>
                  {!isUniversityEditable ?
                <a onClick={()=>setIsUniversityEditable(true)}
                >{myProfileInfo.university!=null?myProfileInfo.university:'Chưa có dữ liệu'}</a>
                : <Select showSearch={true} style={{width:'300px'}}
                optionFilterProp="children"
                filterOption={(input, option:any) => {
                  return (
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  );        
                }}
                options={optionsUniversity}
                onSelect={async(value)=>{
                  setMyProfileInfo({...myProfileInfo, university: value});
                  await updateCv({field:'University', value});
                  setIsUniversityEditable(false);
                }}
                />
              }
                </>         
              </td>:<td>{myProfileInfo.university!=null?myProfileInfo.university:'Chưa có dữ liệu'}</td>}
            </tr>
            <tr>
              <td>Vị trí đăng ký ứng tuyển:</td>             
              <td>{myProfileInfo.internSpecialized}
              </td>
            </tr>
            <tr></tr>
            <tr>
              <td>Các vị trí khác muốn ứng tuyển:</td>             
              <td><Select style={{width:'300px'}}
                  mode="multiple" disabled = {props.readOnlyOption?true:false}
                  value={myProfileInfo.expectedJobs??[]}
                  placeholder={"Các vị trí việc làm mong muốn"}
                  onChange={handleChange1}
                  options={optionsJDs}
              />
              </td>
            </tr>
            <tr>
            <td>Trình độ ngoại ngữ:</td>
            {!props.readOnlyOption?
              <td>
                <>
                  {!isForeignLangEditable ?
                <a onClick={()=>setIsForeignLangEditable(true)}
                >{myProfileInfo.foreignLanguage!=null?myProfileInfo.foreignLanguage:'Chưa có dữ liệu'}</a>
                : <input value={myProfileInfo.foreignLanguage} 
                onChange = {(e)=> {
                  setMyProfileInfo({...myProfileInfo, foreignLanguage: e.currentTarget.value})
                }}
                onKeyPress = {async (e) => {
                  if(e.key == 'Enter'){
                    await updateCv({field:'ForeignLanguage', value: e.currentTarget.value});
                    setIsForeignLangEditable(false);
                  }                 
                }}
                />}
                </>         
              </td>:<td>{myProfileInfo.foreignLanguage!=null?myProfileInfo.foreignLanguage:'Chưa có dữ liệu'}</td>}
            </tr>
            <tr>
            <td>Tập tin CV đính kèm:</td>
            {!isCvEditable ?
              <td>
              <a href='#'
              onClick = {()=>{
                const token = localStorage.getItem('token');
                fetch(process.env.REACT_APP_API_URL+'api/Register/files/' + myProfileInfo.fileUrl, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
            })
            .then(response => response.blob())
        .then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            a.download = myProfileInfo.fileUrl;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();    
            a.remove();  //afterwards we remove the element again
        });
            }}
              >Download CV</a>&nbsp;&nbsp;
              {!props.readOnlyOption ?
              <a onClick={()=>{
                setIsCVEditable(true);
              }}>{'Cập nhật lại file CV'}</a> : '' }
              </td>
              : <td>
                <Input type="file" onChange={async(e:ChangeEvent<HTMLInputElement>)=>{
          const temp = e.target.files;
          if(!temp) 
            return;
          else
            setCvFile(temp[0])
            await updateCv({field:'CVFile', value: temp[0]});
            setIsCVEditable(false);
            }} />
              </td>
          }
            </tr>
            <tr>
            <td>Bạn đã sẵn sàng đi làm:</td>
            <td>
            <Radio.Group onChange={onChangeReady} value={valueReady}>
                <Radio value={1}>Đã sẵn sàng</Radio>
                <Radio value={2}>Chưa sẵn sàng</Radio>
            </Radio.Group>
            </td>
            </tr>

        </table>
    </TabPane>
    <TabPane tab={'Kinh nghiệm làm việc'} key="1">
    <Button onClick={()=>{form.setFieldsValue({
    eventName: '', // Default values
    eventDetail: '',
    eventDate: dayjs(new Date()),
    endDate: dayjs(new Date())
  }); setEventType(1); setInternHistoryId(0);  showModal()}}
    disabled = { props.readOnlyOption } 
    >Thêm công việc</Button>
      <Table columns={columns} dataSource={myProfileInfo.internHistories.filter((x:any)=>x.eventType==1)} />
    </TabPane>
    <TabPane tab={'Các dự án'} key="3">
    <Button onClick={()=>{form.setFieldsValue({
    eventName: '', // Default values
    eventDetail: '',
    eventDate: dayjs(new Date()),
    endDate: dayjs(new Date())
  }); setEventType(3); setInternHistoryId(0);  showModal()}}
    disabled = {props.readOnlyOption } 
    >Thêm các dự án</Button>
      <Table columns={columns} dataSource={myProfileInfo.internHistories.filter((x:any)=>x.eventType==3)} />
    </TabPane>
    <TabPane tab={'Quá trình đào tạo'} key="2">
    <Button onClick={()=>{form.setFieldsValue({
    eventName: '', // Default values
    eventDetail: '',
    eventDate: dayjs(new Date()),
    endDate: dayjs(new Date())
  }); setEventType(2); setInternHistoryId(0);  showModal()}}
    disabled = {props.readOnlyOption } 
    >Thêm quá trình đào tạo</Button>
      <Table columns={columns} dataSource={myProfileInfo.internHistories.filter((x:any)=>x.eventType==2)} />
    </TabPane>
    <TabPane tab={'Lời giới thiệu'} key="4">
      <Button disabled={props.readOnlyOption} onClick={async()=>{
        if(!isIntroductionEditable)
          setIsIntroductionEditable(true)
        else{
          //console.log(myProfileInfo.introduction);
          await updateCv({field:'Introduction', value: myProfileInfo.introduction});
          setIsIntroductionEditable(false)
        }
      }}
      >
        {!isIntroductionEditable?'Cập nhật lời giới thiệu':'Lưu dữ liệu'}</Button>
        <TextArea value={myProfileInfo.introduction} rows={5} disabled={!isIntroductionEditable} 
        onChange = {(e)=> {
          setMyProfileInfo({...myProfileInfo, introduction: e.currentTarget.value})
        }}
        />
    </TabPane>
    <TabPane tab={'Xem CV'} key="5">
    </TabPane>
  </Tabs>
    </div>
        <Modal title={'Thay đổi ảnh đại diện'}
            open={isModalOpen} onOk={async ()=>{
              if(imageData){
                  await updateCv({field:'Avatar', value: imageData});
              }          
            setIsModalOpen(false);      
          }}
            onCancel={()=>setIsModalOpen(false)} >
                Chọn ảnh: <Input type="file" accept="image/png, image/jpeg, image/jpg"
                onChange={(e:ChangeEvent<HTMLInputElement>)=>{
          const temp = e.target.files;
          if(!temp) 
            return;
          else{
            const reader = new FileReader();
            reader.onload = () => {
                    const result = reader.result;
                    setMyProfileInfo({...myProfileInfo, internImageUrl:result});
            };
            reader.readAsDataURL(temp[0]);       
            setImageData(temp[0]);
          }
          }} />
        </Modal>
        <Modal title="Thêm kinh nghiệm/đào tạo" open={isModalHistoryOpen} width={800} footer={null}
        onOk={handleOk} onCancel={handleCancel}>
        <Form labelCol={{ span: 10 }} form={form}
        wrapperCol={{ span: 18 }}
        layout="horizontal" 
        onFinish={onFinish} >
            <Form.Item label="Bắt đầu:" 
        name="eventDate"
        rules={[{ required: true, message: 'Không được để trống!' }]}>
          <DatePicker picker="month" defaultValue={formData.eventDate} onChange={(date)=>setFormData({...formData, eventDate:date})} />
        </Form.Item>
        <Form.Item label="Kết thúc:" 
        name="endDate"
        rules={[{ required: true, message: 'Không được để trống!' }]}>
          <DatePicker picker="month" defaultValue={formData.endDate} onChange={(date)=>setFormData({...formData, endDate:date})} />
        </Form.Item>
        <Form.Item label="Mô tả ngắn công việc/đào tạo:" 
        name="eventName"
        rules={[{ required: true, message: 'Không được để trống!' }]}>
          <TextArea rows={4} defaultValue={formData.eventName} onChange={(e)=>setFormData({...formData, eventName:e.currentTarget.value})} />
        </Form.Item>
        <Form.Item label="Chi tiết công việc:" 
        name="eventDetail"
        >
          <ReactQuill defaultValue={formData.eventDetail}
        modules={{
          toolbar: [
            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
            [{size: []}],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{'list': 'ordered'}, {'list': 'bullet'}, 
             {'indent': '-1'}, {'indent': '+1'}],
            ['clean']
          ],
        }}
        formats={[
          'header', 'font', 'size',
          'bold', 'italic', 'underline', 'strike', 'blockquote',
          'list', 'bullet', 'indent'
        ]}
        onChange={(value)=>setFormData({...formData, eventDetail:value})}
      />
        </Form.Item>
        <Form.Item label="Hiển thị trong CV:" 
        name="displayInCV" valuePropName='checked'
        rules={[{ required: true, message: 'Không được để trống!' }]}>
          <Checkbox />
        </Form.Item>
        <Form.Item label="" wrapperCol={{offset:4}}>
          <Button type="primary" htmlType="submit">Lưu dữ liệu</Button>
        </Form.Item>
        </Form>
    </Modal>
    <Modal title="Xóa dữ liệu" open={isConfirmModalOpen} onOk={()=>handleConfirmOk(internHistoryId)} onCancel={()=>setIsConfirmModalOpen(false)}>
        <h3 style={{color:'#FF0000'}}>Bạn có chắc chắn xóa công việc có id {internHistoryId} này khỏi hệ thống?</h3>
    </Modal>
    <Modal title="Chọn mẫu CV" open={isCvModalOpen} onOk={()=>handleGenerateCv(value)} onCancel={()=>setIsCvModalOpen(false)}>
    <Radio.Group onChange={onChange} value={value}>
      <Space direction="vertical">
        <Radio value={1}>Biểu mẫu 1</Radio>
        <Radio value={2}>Biểu mẫu 2</Radio>
      </Space>
    </Radio.Group>
    </Modal>
      </div>:<div>Loading...</div>}
    </>
  )
}
export default EditCv; 